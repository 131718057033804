import {Formik, Form, FormikValues} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {IEditArticle, IEditArticleForm} from '../../../core/_models'
import {editArticle} from '../../../core/_requests'
import {useQueryClient} from 'react-query'
import {IWebsite} from '../../../../websites/core/_models'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import SerpPreview from 'react-serp-preview'

interface Props {
  article: IEditArticle
  invalidateArticle: () => void
}

export function ArticleMeta({article, invalidateArticle}: Props) {
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)

  const website = websitesData?.find((item) => item.id === article.website.id)

  const {locale = website?.mainLocale || 'uk'} = useParams()

  const initTranslations =
    article?.translations && article?.translations[locale]
      ? {...article?.translations[locale]}
      : {
          title: '',
          shortDescription: '',
          description: '',
          metaDescription: '',
          metaKeywords: '',
          metaTitle: '',
        }
  const initialValues: IEditArticleForm = {
    ...article,
    website: {id: article.website?.id},
    ...initTranslations,
  }

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('desktop')

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: IEditArticleForm, actions: FormikValues) => {
    console.log('values', values)
    const {metaDescription, metaKeywords, metaTitle} = values
    setLoading(true)

    try {
      let translations = {
        ...article.translations,
        [locale]: {metaDescription, metaKeywords, metaTitle},
      }

      await editArticle({translations}, article.id)

      invalidateArticle()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      {article?.translations ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form' id='form-meta-blog'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                <div className='d-flex justify-content-center'>
                  <ul className='nav mb-5'>
                    {' '}
                    {[
                      {value: 'desktop', label: "Комп'ютер"},
                      {value: 'mob', label: 'Мобільний'},
                    ].map((i) => (
                      <li className='nav-item' key={i.value}>
                        <button
                          type='button'
                          onClick={() => {
                            setActiveTab(i.value)
                          }}
                          className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary ${
                            activeTab === i.value ? 'active' : ''
                          } fw-bold px-4`}
                        >
                          {i.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='d-flex justify-content-center mb-4'>
                  {activeTab === 'mob' && (
                    <div className='border p-3'>
                      <SerpPreview
                        title={values.title || ''}
                        metaDescription={values.description || ''}
                        url={`https://${website?.host}${locale !== 'uk' ? `/${locale}` : ''}/blog/${
                          article.slug
                        }`}
                        width={375}
                      />
                    </div>
                  )}
                  {activeTab === 'desktop' && (
                    <div className='border p-3'>
                      <SerpPreview
                        title={values.title || ''}
                        metaDescription={values.description || ''}
                        url={`https://${website?.host}${locale !== 'uk' ? `/${locale}` : ''}/blog/${
                          article.slug
                        }`}
                        width={600}
                      />
                    </div>
                  )}
                </div>
                {['metaTitle', 'metaDescription', 'metaKeywords'].map((metaItem, index) => (
                  <div className='row align-items-center' key={index}>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>{metaItem} </label>

                    <div className='col-lg-8'>
                      <InputTemplate inputName={metaItem} required={false} type='textarea' />
                    </div>
                  </div>
                ))}{' '}
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
