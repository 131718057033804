/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import dateToHoursMinsSeconds from '../../../../../../../_metronic/helpers/custom/funcs/time/dateToHoursMinsSeconds'
import {IChat} from '../../../../core/_models'

type Props = {
  chat: IChat
}

const ChatStatusCell = ({chat}: Props) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const statuses: Record<string, string> =
    queryClient.getQueryData(QUERIES.CHATS_STATUSES_LIST) || {}

  const currDate = new Date()

  return (
    <div className='d-flex justify-content-start flex-column'>
      <span>{chat.status.message}</span>
      {chat.outcome && (
        <>
          <div className='mb-2 mt-1'>
            <span
              className={`p-1 fs-7 fw-semobold StatusBar__status ${statusColor(
                chat.outcome === 'partly' ? 'on hold' : chat.outcome === 'full' ? true : false
              )}`}
            >
              {statuses[chat.outcome]}
            </span>
          </div>
        </>
      )}
      {chat?.status?.status === 1 && (
        <span className='fw-normal text-black d-block fs-7'>
          {dateToHoursMinsSeconds(chat?.createdAt, currDate)}
        </span>
      )}
      {(chat?.status?.status === 2 || chat?.status?.status === 4) && (
        <span className='fw-normal text-black d-block fs-7'>
          {dateToHoursMinsSeconds(chat?.createdAt, chat?.status?.createdAt)}
        </span>
      )}
    </div>
  )
}

export {ChatStatusCell}
