import {useFormikContext} from 'formik'
import {useIntl} from 'react-intl'
import Select, {components} from 'react-select'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {customStyles} from '../ReactSelectTemplate'

interface IProps {
  categoriesAvailable: (IBasicSelect & {image: string})[]
  values: any
  inputName?: string
}

const CategoriesMultipleSelect = ({
  categoriesAvailable,
  values,
  inputName = 'categories',
}: IProps) => {
  const intl = useIntl()
  const {setFieldValue, resetForm, dirty} = useFormikContext()

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Не знайдено</span>
      </components.NoOptionsMessage>
    )
  }
  return (
    <Select
      isMulti
      components={{NoOptionsMessage}}
      styles={customStyles}
      loadingMessage={() => intl.formatMessage({id: 'GENERAL.BUTTONS.WAIT'})}
      placeholder='Оберіть категорії'
      name={inputName}
      defaultValue={values.categories}
      options={categoriesAvailable}
      className='basic-multi-select'
      classNamePrefix='select'
      onChange={(selectedOptions: any) => {
        setFieldValue(inputName, selectedOptions)
      }}
      formatOptionLabel={(i) => (
        <div className='tagify__dropdown__item d-flex align-items-center h-30px'>
          <div className='tagify__dropdown__item__avatar-wrap me-2 cursor-pointer'>
            <img
              alt=''
              className='rounded-circle w-25px h-25px me-2'
              src={i.image}
              style={{objectFit: 'cover'}}
            />
          </div>

          <div className='d-flex flex-column text-gray-800'>
            <strong>{i.label}</strong>
          </div>
        </div>
      )}
    />
  )
}

export default CategoriesMultipleSelect
