import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
}
const RegisteredFilter = ({savedFilter, setSavedFilter}: TProps) => {
  return (
    <div className='mb-10'>
      <label className='form-label fw-bold'>Користувач зареєстрований:</label>

      <div className='d-flex'>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            name='telegramChat'
            type='radio'
            value={undefined}
            checked={savedFilter?.telegramChat === undefined}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, telegramChat: undefined}
              })
            }}
          />
          <span className='form-check-label'>
            <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALL' />
          </span>
        </label>
        <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
          <input
            className='form-check-input'
            name='telegramChat'
            type='radio'
            value={1}
            checked={+savedFilter?.telegramChat === 1}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, telegramChat: 1}
              })
            }}
          />
          <span className='form-check-label'>
            Так
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.ALLOWED' /> */}
          </span>
        </label>

        <label className='form-check form-check-sm form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            name='telegramChat'
            type='radio'
            value={0}
            checked={+savedFilter?.telegramChat === 0}
            onChange={() => {
              setSavedFilter((prev) => {
                return {...prev, telegramChat: 0}
              })
            }}
          />
          <span className='form-check-label'>
            Ні
            {/* <FormattedMessage id='TRACKS.PAGE.LIST.FILTER.SHIPMENT_STATUS.PROH' /> */}
          </span>
        </label>
      </div>
    </div>
  )
}

export default RegisteredFilter
