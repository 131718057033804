/* eslint-disable jsx-a11y/anchor-is-valid */

import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl'
import {FULF_RECEIVER_VERIFICATION_STATUS, KTSVG} from '../../../../../../../_metronic/helpers'
import {idZeroPad} from '../../../receivers-list/core/funcs'
import {IReceiverLog} from '../../../receivers-list/core/_models'

interface Props {
  log: IReceiverLog
}

const {VERIFIED, CHECKED, WAITING} = FULF_RECEIVER_VERIFICATION_STATUS

function ReceiverLogsItem({log}: Props) {
  const intl = useIntl()

  const dataLabels: Record<string, string> = {
    enabled: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS.ENABLED_LABEL'}),
    document: intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE'}),
    email: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS.EMAIL_LABEL'}),
    final: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS.FINAL_LABEL'}),
    withinCountry: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS.WITHINCOUNTRY_LABEL'}),
    lastName: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS.LASTNAME_LABEL'}),
    firstName: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS.FIRSTNAME_LABEL'}),
    international: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.LOGS.INT_LABEL'}),
    user: 'Користувач, якому належить отримувач',
    patronymic: 'По-батькові',
    noPatronymic: 'Нема по-батькові',
    verifStatus: 'Верифікація',
    documentNumber: intl.formatMessage({id: 'RECEIVERS.REC_PAGE.GENERAL.DOC_NUMBER_LABEL'}),
  }

  const renderLogChange = () => {
    return Object.entries(log.data).map(([key, value]) => {
      const label = dataLabels[key] || key
      const verif = value === CHECKED || value === VERIFIED || value === WAITING
      const getVerifStatus = (status: string) => {
        switch (status) {
          case VERIFIED:
            return <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />
          case CHECKED:
            return <FormattedMessage id='GENERAL.STATUSES.CHECKED' />
          case WAITING:
            return <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />
          default:
            break
        }
      }
      return (
        <div key={key}>
          <span className='fw-bold'>{label}: </span>
          {!value || typeof value === 'boolean' ? (
            !value && key === 'document' ? (
              <span>Видалено скан паспорта</span>
            ) : !value ? (
              <KTSVG
                path='/media/icons/duotune/general/gen040.svg'
                className='svg-icon-3 svg-icon-danger svg-bg-white'
              />
            ) : (
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon-3 svg-icon-success'
              />
            )
          ) : (
            <span>
              {typeof value === 'object'
                ? [].concat.apply([], Object.values(value))
                : verif
                ? getVerifStatus(value)
                : value}
            </span>
          )}
        </div>
      )
    })
  }

  return (
    <tr>
      <td>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={log.date} />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime value={log.date} hour='numeric' minute='numeric' second='numeric' />
          </span>
        </div>
      </td>

      {/* <td>
        <span className='text-dark text-hover-primary d-block fs-6'>{log.action}</span>
      </td> */}
      <td>
        <span className='text-dark d-block fs-6 mw-500px' style={{overflowWrap: 'break-word'}}>
          {renderLogChange()}
        </span>
      </td>
      <td>
        <div className='d-flex flex-column'>
          <span className='fw-bold text-black d-block fs-6 me-1'>
            {idZeroPad(log.user.id, 5) || '-'}
          </span>{' '}
          <span className='fw-normal text-black d-block fs-7'>{log.user.name}</span>
        </div>
      </td>
    </tr>
  )
}

export {ReceiverLogsItem}
