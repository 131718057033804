import {FC} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {ICountry} from '../../../core/_models'

type Props = {
  country: ICountry
}

const CountryNameCell: FC<Props> = ({country}) => {
  const queryClient = useQueryClient()

  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  return (
    <div className='d-flex align-items-center'>
      <p className='fw-semobold text-black d-block fs-6 me-1'>{countries[country.country]}</p>
    </div>
  )
}

export {CountryNameCell}
