import {Dispatch, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryResponse} from '../../../../core/QueryResponseProvider'
import CreatedByFilter from './CreatedByFilter'
import ShipmentStatusFilter from './ShipmentStatusFilter'
import TypeFilter from './TypeFilter'
import DirectionFilter from './DirectionFilter'
import RegisteredFilter from './RegisteredFilter'

type TProps = {
  savedFilter: Record<string, any>
  setSavedFilter: Dispatch<SetStateAction<Record<string, any>>>
  resetFilter: () => void
  filterData: () => void
}

const GeneralFilter = ({savedFilter, setSavedFilter, resetFilter, filterData}: TProps) => {
  const {response, isLoading} = useQueryResponse()

  return (
    <div>
      <button
        disabled={isLoading}
        type='button'
        className={`btn btn-sm shadow-sm btn-${
          savedFilter && Object.keys(savedFilter)?.length > 0
            ? 'primary'
            : 'light-primary btn-color-muted'
        } me-3`}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-id='filter-menu'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        <FormattedMessage id='GENERAL.TABLE_FILTER.HEADER' />: {response?.totalItems}
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
        data-kt-menu='true'
        id='filter-menu'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'> Фільтр</div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-3' data-kt-user-table-filter='form'>
          <ShipmentStatusFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter}/>

          <TypeFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter}/>

          <CreatedByFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter}/>

          <DirectionFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter}/>

          <RegisteredFilter savedFilter={savedFilter} setSavedFilter={setSavedFilter}/>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              disabled={isLoading}
              onClick={resetFilter}
              className='btn btn-sm btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.RESET'/>
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-sm btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.APPLY'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralFilter
