import {FC} from 'react'
import {useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {IWarehouse} from '../../../../core/_models'

type Props = {
  warehouse: IWarehouse
}

const WarehouseCountryCell: FC<Props> = ({warehouse}) => {
  const queryClient = useQueryClient()
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}
  return (
    <div className='d-flex align-items-center'>
      <p className='fw-semobold text-black d-block fs-6 me-1'>
        {countries[warehouse.countryConfig.country]}
      </p>
    </div>
  )
}

export {WarehouseCountryCell}
