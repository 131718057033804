import {FormattedDate, FormattedTime} from 'react-intl'
import {IStore} from '../../../../core/_models'

type Props = {
  store: IStore
}

const StoreDateCell = ({store}: Props) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <span className='fw-semobold text-black d-block fs-6 me-1'>
          {store.author
            ? `${store.author.firstName} ${store.author.lastName || ''}`
            : 'Неавторизований'}
        </span>
        <span className='fw-normal text-black d-block fs-7'>
          <FormattedDate year='numeric' month='numeric' day='numeric' value={store.createdAt} />,{' '}
          <FormattedTime
            value={new Date(store.createdAt)}
            hour='numeric'
            minute='numeric'
            second='numeric'
          />
        </span>
      </div>
    </div>
  )
}

export {StoreDateCell}
