/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {
  getSavedFilter,
  initialQueryState,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../../../core/QueryRequestProvider'
import {useQueryResponsePagination} from '../../../../core/QueryResponseProvider'
import {FormattedMessage, useIntl} from 'react-intl'
import DatePicker from './DatePicker'
import GeneralFilter from './GeneralFilter'
import {getDateForBackend} from '../../../../core/_funcs'

type TActiveDateSelect = null | 1 | 2 | 3 | 4 // 1 - curr day, 2 - week, 3 - month, 4 - year

const ChatsListFilter = () => {
  const pagination = useQueryResponsePagination()
  const {updateState, state} = useQueryRequest()

  const [savedFilter, setSavedFilter] = useState<Record<string, any>>({})

  useEffect(() => {
    MenuComponent.reinitialization()
    const savedFilter = getSavedFilter(QUERIES.CHATS_LIST)
    if (savedFilter) {
      setSavedFilter(savedFilter)
    }
  }, [])

  useEffect(() => {
    filterData()
  }, [savedFilter?.createdAfter, savedFilter?.createdBefore])

  const [dateState, setDateState] = useState<any>({
    startDate: savedFilter?.createdAfter || undefined,
    endDate: savedFilter?.createdBefore || undefined,
  })

  const resetFilter = () => {
    updateState({
      filter: undefined,
      ...initialQueryState,
      itemsPerPage: pagination.itemsPerPage || 10,
    })
    setDateState(undefined)
    setSavedFilter({})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.CHATS_LIST), '{}')

    const allMenus = document.querySelectorAll('.menu.show')
    const allMenusArray = Array.from(allMenus)
    allMenusArray.map((menu) => menu.classList.remove('show'))
  }

  const filterData = () => {
    let createdAfter: string | undefined = dateState?.startDate
      ? getDateForBackend(dateState?.startDate)
      : undefined
    let createdBefore: string | undefined = dateState?.endDate
      ? getDateForBackend(dateState?.endDate)
      : undefined

    const newFilters = {
      closed: savedFilter?.closed,
      activeDateSelect: savedFilter?.activeDateSelect || undefined,
      createdAfter,
      createdBefore,
      task: savedFilter?.task,
      createdByUser: savedFilter?.createdByUser,
      direction: savedFilter?.direction?.value,
      telegramChat: savedFilter?.telegramChat,
    }

    updateState({
      ...state,
      filter: {...state.filter, ...newFilters},
    })
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.CHATS_LIST),
      JSON.stringify(newFilters)
    )
  }

  return (
    <div className='d-flex justify-content-end'>
      {savedFilter && Object.keys(savedFilter)?.length > 0 && (
        <button type='button' className='btn btn-sm btn-light-primary me-3' onClick={resetFilter}>
          <FormattedMessage id='GENERAL.TABLE_FILTER.RESET_OUTER_BUTTON' />{' '}
        </button>
      )}
      <DatePicker
        resetFilter={resetFilter}
        filterData={filterData}
        dateState={dateState}
        setDateState={setDateState}
        savedFilter={savedFilter}
        setSavedFilter={setSavedFilter}
      />{' '}
      <GeneralFilter
        savedFilter={savedFilter}
        setSavedFilter={setSavedFilter}
        resetFilter={resetFilter}
        filterData={filterData}
      />
    </div>
  )
}

export {ChatsListFilter}
