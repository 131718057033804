import {FC} from 'react'

import {EmailTemplate} from '../../../core/_models'

type Props = {
  template: EmailTemplate
}

const TemplateNameCell: FC<Props> = ({template}) => {
  return (
    <div className='d-flex align-items-center'>
      <p className='fw-semobold text-black d-block fs-6 me-1'>{template.subject}</p>
    </div>
  )
}

export {TemplateNameCell}
