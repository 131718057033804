import {Form, Formik, FormikValues} from 'formik'
import React, {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import {CARDFOOTER_NOTIFICATIONS, QUERIES} from '../../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../../websites/core/_models'
import {CardFooter} from '../../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {ICollection, IEditCollection, IEditCollectionForm} from '../../../../_models'
import {editCollection} from '../../../core/_requests'
import WebsitesSelect from '../../../../../../modules/custom/form-elements/selects/WebsitesSelect'

interface Props {
  collection: ICollection
  type: number
  invalidateCollection: () => void
}
const CollectionOverview = ({collection, type, invalidateCollection}: Props) => {
  const intl = useIntl()
  const {locale = 'uk'} = useParams()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const website = websitesData?.find((item) => item.id === collection?.website?.id)

  const initialValues: IEditCollectionForm = {
    type,
    name: collection.translations[locale].name,
    website: {...website, value: website?.id || '', label: website?.name || ''}
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: IEditCollectionForm, actions: FormikValues) => {
    const {name} = values
    setLoading(true)

    try {
      let translations = {
        ...collection?.translations,
        [locale]: {name},
      }

      const dataToSend: Partial<IEditCollection> = {
        translations,
        website: {id: values.website?.value},
      }

      await editCollection(dataToSend, collection.id)

      invalidateCollection()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
      actions.resetForm()
    }
  }

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      <Formik
        // validationSchema={() => {}}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({values, setFieldValue, errors}) => (
          <Form noValidate className='form' id='form-overview-blog'>
            {/* {JSON.stringify(values)} */}
            {/* {JSON.stringify(errors)} */}
            <div className='card-body p-9'>
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Сайт</label>{' '}
                <div className='col-lg-8'>
                  {' '}
                  <WebsitesSelect withTitle={false} />
                </div>
              </div>{' '}
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва</label>

                <div className='col-lg-8'>
                  <InputTemplate inputName='name' required={true} type='text' />
                </div>
              </div>{' '}
              <div className='row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <FormattedMessage id={'BLOG.ADD_MODAL.FORM.SLUG_LABEL'} />
                </label>
              </div>
            </div>
            <CardFooter loading={loading} success={footerNotification?.success || false} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CollectionOverview
