import {FormattedDate, FormattedTime} from 'react-intl'
import {IStore} from '../../../../core/_models'

type Props = {
  store: IStore
}

const StoreBuyoutsCell = ({store}: Props) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <span className='fw-semobold text-black d-block fs-6 me-1'>{store.ordersCount || 0}</span>
      </div>
    </div>
  )
}

export {StoreBuyoutsCell}
