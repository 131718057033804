/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {KTSVG, QUERIES, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {useMutation, useQueryClient} from 'react-query'
import {directionsFuncs} from '../../../../_metronic/helpers/custom/funcs/directions'
import {websitesApi} from '../core/_requests'
import {CreateError} from '../responseHandlers/CreateError'
import {CreateLoading} from '../responseHandlers/CreateLoading'
import {PhotoshopPicker} from 'react-color'
import {WebsiteLink} from '../elements/WebsiteLink'
import linksData from '../../../../data/websites/linksWebsite.json'
import {Notification} from '../../../modules/custom/Notification'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {createWebsiteSchema} from '../core/yup'
import logosData from '../../../../data/websites/websiteLogos.json'
import {Modal} from 'react-bootstrap'
import {WebsiteLogoItem} from '../elements/WebsiteLogoItem'
import {ICreateNewWebsite} from '../core/_models'
import {WebsiteDirection} from '../elements/WebsiteDirection'
import {DirectionsQueryResponse} from '../../directions/core/_models'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {WebsiteLanguage} from '../elements/WebsiteLanguage'

const {selectedLang} = getConfig()

const inits: ICreateNewWebsite = {
  host: '',
  name: '',
  description: '',
  logo: null,
  logoDark: null,
  favicon: null,
  mainColor: '#ffffff',
  links: [],
  directions: [],
  enabledLocales: [],
  mainLocale: selectedLang,
}
interface Props {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
}

const CreateWebsite = ({setIsOpen, isOpen}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const queryClient = useQueryClient()

  const [createResponse, setCreateResponse] = useState('')

  const [currentSchema, setCurrentSchema] = useState(createWebsiteSchema[0])
  const [initValues] = useState<ICreateNewWebsite>(inits)
  const [currentColor, setCurrentColor] = useState('')

  const directions: DirectionsQueryResponse =
    queryClient.getQueryData([QUERIES.DIRECTIONS_WITH_IDS_LIST]) || {}
  const countries: Record<string, string> =
    queryClient.getQueryData([QUERIES.COUNTRY_ABBR_LIST]) || {}

  const mutation = useMutation(websitesApi.addWebsite, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.WEBSITES_LIST)
      setCreateResponse('success')
    },
    onMutate: () => {
      setCreateResponse('loading')
    },
    onError: (error) => {
      setCreateResponse('error')
      console.log(error)
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createWebsiteSchema[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateNewWebsite, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createWebsiteSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 1) {
      stepper.current?.goNext()
      const {
        description,
        directions,
        host,
        logo,
        logoDark,
        favicon,
        links,
        mainColor,
        name,
        enabledLocales,
        mainLocale,
      } = values

      const websiteData = {
        host: host,
        name: name,
        directions: directions.map((i) => ({id: i})),
        description: description,
        links: links,
        mainColor: mainColor,
        logo: logo,
        logoDark: logoDark,
        favicon: favicon,
        mailerDns: null,
        fromEmail: null,
        enabledLocales,
        mainLocale,
      }

      mutation.mutate(websiteData)
      stepper.current?.goNext()
    } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px'
      id='kt_modal_create_app'
      aria-hidden='true'
    >
      <div className='modal-content    CreateWebsite__overflow-container'>
        <div className='modal-header'>
          <h2>Создать сайт</h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
              <div className='stepper-nav ps-lg-10'>
                <div className='stepper-item current CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    {' '}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Общее</h3>

                      <div className='stepper-desc'>Базовые понятия</div>
                    </div>
                  </div>
                </div>
                <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-line h-40px'></div>

                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Лого</h3>

                      <div className='stepper-desc'>Добавьте лого</div>
                    </div>
                  </div>
                </div>
                <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-line h-40px'></div>

                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Цвет</h3>

                      <div className='stepper-desc'>Выберите основной цвет</div>
                    </div>
                  </div>
                </div>
                <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-line h-40px'></div>

                  <div className='stepper-wrapper'>
                    {' '}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Полезные ссылки</h3>

                      <div className='stepper-desc'>Укажите ссылки</div>
                    </div>
                  </div>
                </div>{' '}
                <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-line h-40px'></div>

                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>5</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Направления</h3>

                      <div className='stepper-desc'>Добавьте направления</div>
                    </div>
                  </div>
                </div>{' '}
                <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-line h-40px'></div>

                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>6</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Мови</h3>

                      <div className='stepper-desc'>Оберіть активні мови сайту</div>
                    </div>
                  </div>
                </div>
                <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-line h-40px'></div>

                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>7</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Готово</h3>

                      <div className='stepper-desc'>Сайт создан</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={currentSchema}
                initialValues={initValues}
                onSubmit={submitStep}
              >
                {({values, setFieldValue}) => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    {/* {JSON.stringify(values)} */}
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-10'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Домен</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Укажите домен'
                            ></i>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='host'
                            placeholder=''
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='host' />
                          </div>
                        </div>
                        <div className='fv-row mb-10'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Название</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Укажите название сайта'
                            ></i>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='name'
                            placeholder=''
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>
                        <div className='fv-row mb-10'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span>Опис</span>
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Добавьте описание'
                            ></i>
                          </label>

                          <Field
                            as='textarea'
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='description'
                            placeholder=''
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='description' />
                          </div>
                        </div>
                        <Notification
                          noteText='Данные введенные в поле Описание для внутреннего использования компании и доступны только ее сотрудникам.'
                          borderTop={true}
                        />
                      </div>
                    </div>
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                            <span>Загрузите логотипы</span>
                          </label>

                          <ul className='CreateWebsite__logos'>
                            {logosData.map(({id, title, inputName}) => (
                              <WebsiteLogoItem
                                key={id}
                                title={title}
                                inputName={inputName}
                                setFieldValue={setFieldValue}
                              />
                            ))}
                          </ul>
                        </div>
                        <Notification
                          noteText='Если Вы не укажете логотип, то вместо него будет выводиться надпись с названием сайта.'
                          borderTop={true}
                        />
                        {/* <div className='text-danger'>
                          <ErrorMessage name='logoFiles' />
                        </div> */}
                      </div>
                    </div>
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-10'>
                          <label className='required fs-5 fw-bold mb-2'>Цвет</label>
                          <PhotoshopPicker
                            color={currentColor}
                            onChange={(color) => setCurrentColor(color.hex)}
                            onChangeComplete={(color) => {
                              setCurrentColor(color.hex)
                              values.mainColor = color.hex
                            }}
                          />

                          <div className='text-danger'>
                            <ErrorMessage name='color' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        {linksData.map((item, index) => (
                          <WebsiteLink key={item.id} index={index} />
                        ))}
                      </div>
                    </div>
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-10'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                            <span className='required'> Направления</span>{' '}
                            <i
                              className='fas fa-exclamation-circle ms-2 fs-7'
                              data-bs-toggle='tooltip'
                              title='Выберите направление(я)'
                            ></i>
                          </label>{' '}
                          {directions?.data?.map(({id, countryTo, countryFrom}) => (
                            <WebsiteDirection
                              key={id}
                              directionId={id}
                              setFieldValue={setFieldValue}
                              chosenDirections={values.directions}
                              transformedDirection={directionsFuncs.formDirection(
                                countryFrom,
                                countryTo,
                                countries
                              )}
                            />
                          ))}{' '}
                        </div>
                        <div className='text-danger'>
                          <ErrorMessage name='directions' />
                        </div>
                      </div>
                    </div>
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-10'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                            <span>Оберіть активні мови сайту </span>
                          </label>
                          {['uk', 'en', 'ru'].map((locale) => (
                            <WebsiteLanguage language={locale} />
                          ))}

                          <div className='text-danger'>
                            <ErrorMessage name='enabledLocales' />
                          </div>
                        </div>
                        <div className='fv-row'>
                          {values.enabledLocales.length > 0 && (
                            <>
                              <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
                                <span>Оберіть мову за замовчуванням</span>
                              </label>
                              {values.enabledLocales.map((locale) => (
                                <WebsiteLanguage
                                  language={locale || values.enabledLocales[0]}
                                  defaultLang={true}
                                />
                              ))}
                              <div className='text-danger'>
                                <ErrorMessage name='mainLocale' />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div data-kt-stepper-element='content'>
                      <div className='w-100 text-center'>
                        {createResponse === 'success' && (
                          <>
                            <h1 className='fw-bolder text-dark mb-3'>
                              Поздравляем! Вы успешно привязали сайт к личному кабинету.
                            </h1>
                            {stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! && (
                              <div className='me-2'>
                                <button
                                  type='submit'
                                  className='btn btn-lg btn-light-primary me-3'
                                  data-kt-stepper-action='previous'
                                >
                                  Добавить ещё один сайт
                                </button>
                              </div>
                            )}
                            <div className='text-center px-4 py-15'>
                              <img
                                src={toAbsoluteUrl('/media/custom/P1.png')}
                                alt=''
                                className='w-auto mh-300px'
                              />
                            </div>
                          </>
                        )}
                        {createResponse === 'loading' && <CreateLoading />}
                        {createResponse === 'error' && <CreateError />}
                      </div>
                    </div>
                    {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber! && (
                      <div className='d-flex flex-stack pt-10'>
                        <div className='me-2'>
                          <button
                            onClick={prevStep}
                            type='button'
                            className='btn btn-lg btn-light-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Назад
                          </button>
                        </div>

                        {stepper.current?.currentStepIndex !==
                          stepper.current?.totatStepsNumber && (
                          <div>
                            <button type='submit' className='btn btn-lg btn-primary me-3'>
                              <span className='indicator-label'>
                                {stepper.current?.currentStepIndex !==
                                  stepper.current?.totatStepsNumber! - 1 && 'Далее'}
                                {stepper.current?.currentStepIndex ===
                                  stepper.current?.totatStepsNumber! - 1 && 'Создать'}
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr064.svg'
                                  className='svg-icon-3 ms-2 me-0'
                                />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateWebsite}
