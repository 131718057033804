import {Form, Formik, FormikValues} from 'formik'
import {useCallback, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import {
  CARDFOOTER_NOTIFICATIONS,
  QUERIES,
  selectDebounce,
} from '../../../../../../_metronic/helpers'
import {IWebsite} from '../../../../websites/core/_models'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {getCategories} from '../../../../categories/stores/core/_requests'
import {ICategorySelectOption} from '../../../../lists/categories/core/_models'
import {IEditStore, IEditStoreForm} from '../../../core/_models'
import {editStore} from '../../../core/_requests'
import WebsitesSelect from '../../../../../modules/custom/form-elements/selects/WebsitesSelect'
import CategoriesMultipleSelect from '../../../../../modules/custom/form-elements/selects/CategoriesMultipleSelect'

interface Props {
  store: IEditStore
  invalidateStore: () => void
}

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`

const StoreOverview = ({store, invalidateStore}: Props) => {
  const {locale = 'uk'} = useParams()
  const queryClient = useQueryClient()
  const websitesData: IWebsite[] | undefined = queryClient.getQueryData(QUERIES.WEBSITES_LIST)
  const website = websitesData?.find((item) => +item.id === store.website.id)
  const initTranslations =
    store?.translations && store?.translations[locale]
      ? {...store?.translations[locale]}
      : {
          title: '',
          shortDescription: '',
          description: '',
          metaDescription: '',
          metaKeywords: '',
          metaTitle: '',
        }

  const initialValues: Partial<IEditStoreForm> = {
    ...Object.fromEntries(
      Object.entries(store).filter(([k, v]) => k !== 'slug' && k !== 'urls' && k !== 'link')
    ),
    website: {...website, value: website?.id, label: website?.name || ''},
    ...initTranslations,
    categories:
      store.categories?.map((i) => ({
        value: i.id,
        label: i.name || '',
        image: i.imageUrl ? `${BASE_URL}${i.imageUrl}` : '/media',
      })) || [],
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: Partial<IEditStoreForm>, actions: FormikValues) => {
    const {title, shortDescription, companyName, name, companyTaxCode} = values
    setLoading(true)

    try {
      let translations = {
        ...store?.translations,
        [locale]: {initTranslations, ...store?.translations?.locale, title, shortDescription},
      }

      const dataToSend: Partial<IEditStore> = {
        website: {id: values.website?.value},
        translations,
        categories: values?.categories?.map((i) => {
          return {id: i.value}
        }),
        companyName,
        companyTaxCode,
      }

      if (
        locale.toLowerCase().trim() === website?.mainLocale.toLowerCase().trim() &&
        name !== title
      ) {
        dataToSend.name = title || name
      }

      await editStore(dataToSend, store.id)

      invalidateStore()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  const [resultsLoading, setResultsLoading] = useState(false)

  const [categoriesResults, setCategoriesResults] = useState<ICategorySelectOption[] | undefined>(
    []
  )

  const filterCategories = useCallback(
    selectDebounce((query: string, callback?: any) => {
      setResultsLoading(true)
      getCategories(`search=${query}`)
        .then((res) => {
          const reformedCategories = res.data?.map((item) => {
            return {
              ...item,
              value: item.id,
              label: item.name,
            }
          })
          // @ts-ignore
          setCategoriesResults(reformedCategories)
          if (callback) {
            callback(reformedCategories)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setResultsLoading(false))
    }, 500),
    [getCategories]
  )

  useEffect(() => {
    filterCategories('')
  }, [filterCategories])

  return (
    <div className='card mb-5 mb-xl-10 pt-5'>
      {categoriesResults && categoriesResults.length > 0 ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form' id='form-overview-store'>
              {/* {JSON.stringify(values)} 
               {JSON.stringify(errors)}  */}
              <div className='card-body p-9'>
                <div className='row align-items-center'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Сайт</label>

                  <div className='col-lg-8'>
                    {' '}
                    <WebsitesSelect withTitle={false} />
                  </div>
                </div>
                <div className='row my-7 align-items-center'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Категорії</label>
                  {/* {JSON.stringify(values.categories)} */}
                  <div className='col-lg-8'>
                    <CategoriesMultipleSelect
                      values={values}
                      categoriesAvailable={categoriesResults.map((i) => ({
                        value: i.id,
                        label: i.name,
                        image: i.imageUrl ? `${BASE_URL}${i.imageUrl}` : '/media',
                      }))}
                    />
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='title' required={true} type='text' />
                  </div>
                </div>{' '}
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Опис</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='shortDescription' required={true} type='text' />
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Назва компанії</label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='companyName' required={true} type='text' />
                  </div>
                </div>
                <div className='row'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Податковий код компанії
                  </label>

                  <div className='col-lg-8'>
                    <InputTemplate inputName='companyTaxCode' required={true} type='text' />
                  </div>
                </div>
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}

export default StoreOverview
