import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Location} from '../../../../core/_models'

type Props = {
  location: Location
}

const LocationRegionCell: FC<Props> = ({location}) => {
  return (
    <div className='d-flex align-items-center'>
      <p className='fw-semobold text-black d-block fs-6 me-1'>{location.region.name}</p>
    </div>
  )
}

export {LocationRegionCell}
