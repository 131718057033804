import {Formik, Form, FieldArray} from 'formik'
import {useState} from 'react'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {CARDFOOTER_NOTIFICATIONS, KTSVG} from '../../../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import PartialPageLoader from '../../../../../../_metronic/layout/core/PartialPageLoader'
import {IEditWebsite, IWebsite} from '../../../core/_models'
import {websitesApi} from '../../../core/_requests'
import AgreementDate from '../../../../settings/editing-page/tabs/overview/AgreementDate'

interface Props {
  website: IWebsite
  invalidateWebsite: () => void
}

export function TermsOfServiceTab({website, invalidateWebsite}: Props) {
  const {locale} = useParams()
  const currLocale = locale || website?.enabledLocales[0] || 'uk'
  const [dateState, setDateState] = useState<any>(new Date(website?.tosUpdatedAt))
  const arrayDescr =
    website?.translations &&
    website?.translations[currLocale] &&
    website?.translations[currLocale].tos
      ?.split(/<\/?p>/)
      .map((item) => item.trim())
      .filter(function (el) {
        return el !== ''
      })
  const initialValues: {tos: string[]; tosUpdatedAt: Date} = {
    tos: arrayDescr && arrayDescr?.length > 0 ? arrayDescr : [''],

    tosUpdatedAt: website?.tosUpdatedAt ? new Date(website?.tosUpdatedAt) : new Date(),
  }

  const [loading, setLoading] = useState(false)

  const [footerNotification, setFooterNotification] = useState<{
    success: boolean
    color: string
    intl: string
    icon: string
  } | null>(null)

  const handleSubmit = async (values: {tos: string[]; tosUpdatedAt: Date}) => {
    setLoading(true)

    try {
      let translations = {
        ...website?.translations,
        [currLocale]: {
          tos: values.tos.reduce((acc, curr) => acc + `<p>${curr.trim()}</p>`, ''),
        },
      }
      const dataToSend: Partial<IEditWebsite> = {
        translations,
        tosUpdatedAt: dateState.toLocaleDateString(),
      }

      // if (typeof dateState === 'string') {
      //   dataToSend.tosUpdatedAt = new Date(dateState)
      // } else {
      dataToSend.tosUpdatedAt = dateState.toLocaleDateString()
      // }

      await websitesApi.editWebsite(website?.id, dataToSend)

      invalidateWebsite()
      setFooterNotification(CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED)

      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.message) {
        setFooterNotification(CARDFOOTER_NOTIFICATIONS(error?.response.data.message).ERROR)

        setTimeout(() => {
          setFooterNotification(null)
        }, 3000)
      }
    } finally {
      setLoading(false)
    }
  }

  const modules = {
    toolbar: [
      [{header: [1, 2, 3, 'normal']}],
      ['bold', 'italic', 'underline', 'strike'],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['link', 'code-block'],
      ['clean'],
    ],
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header d-flex justify-content-between align-items-center py-5'>
        <h3>Текст угоди</h3>{' '}
        <AgreementDate dateState={dateState || website?.tosUpdatedAt} setDateState={setDateState} />
      </div>

      {website ? (
        <Formik
          // validationSchema={() => {}}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, errors, dirty}) => (
            <Form noValidate className='form' id='form-tos-text'>
              {/* {JSON.stringify(values)} */}
              {/* {JSON.stringify(errors)} */}
              <div className='card-body p-9'>
                <FieldArray
                  name='tos'
                  render={(arrayHelpers) => (
                    <div className='d-flex flex-column flex-col-gutter'>
                      {values.tos.map((p, index) => (
                        <div
                          key={index}
                          className={`row ${
                            index === values.tos.length - 1 ? 'd-flex align-items-center' : ''
                          }`}
                        >
                          <div
                            className={`col-lg-3 col-form-label fw-bold fs-6 d-flex flex-column flex-col-gutter align-items-center`}
                          >
                            {values.tos.length > 1 && (
                              <div>
                                <button
                                  className='btn btn-sm btn-secondary d-flex align-items-center color-primary m-0'
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen040.svg'
                                    className='svg-icon-1x svg-icon-primary d-block m-0'
                                  />
                                </button>
                              </div>
                            )}
                            {index === values.tos.length - 1 && (
                              <div>
                                <button
                                  className='btn btn-sm btn-secondary d-flex align-items-center color-primary'
                                  type='button'
                                  onClick={() => {
                                    arrayHelpers.push('')
                                  }}
                                >
                                  {/* <FormattedMessage id='GENERAL.BUTTONS.ADD_GOODS' /> */}
                                  Додати абзац
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen041.svg'
                                    className='svg-icon-1x svg-icon-primary d-block m-0 mx-2'
                                  />
                                </button>
                              </div>
                            )}
                            {/* <div>
                                <button
                                  className='btn btn-sm btn-secondary d-flex align-items-center color-primary m-0'
                                  type='button'
                                  onClick={() => setFieldValue(`tos.${index}`)}
                                >
                                  Приховати
                                </button>
                              </div> */}
                          </div>
                          <div className='col-lg-9'>
                            <ReactQuill
                              theme='snow'
                              value={values.tos[index]}
                              modules={modules}
                              onChange={(e) => setFieldValue(`tos.${index}`, e)}
                            />
                            <InputTemplate
                              inputName={`tos.${index}`}
                              type='textarea'
                              backgroundClass='min-h-150px'
                              fieldWidth='d-none'
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
              <CardFooter loading={loading} success={footerNotification?.success || false} />
            </Form>
          )}
        </Formik>
      ) : (
        <PartialPageLoader />
      )}
    </div>
  )
}
