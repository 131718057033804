import AsyncSelect from 'react-select/async'
import {components, GroupBase} from 'react-select'
import {customStyles} from '../../../../modules/custom/form-elements/ReactSelectTemplate'
import {IBasicSelect} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {ErrorMessage, useFormikContext} from 'formik'
import {useUnpackShipmentContext} from '../unpack/core/UnpackShipmentContext'
import {Dispatch, SetStateAction} from 'react'
import {IReceivedItem} from '../accept/core/_models'
import {IUnpackedItem} from '../unpack/core/_models'
import {getUsers} from '../../../users/core/_requests'
import Select from 'react-select/dist/declarations/src/Select'

interface Props {
  selectRef: React.RefObject<Select<IBasicSelect, false, GroupBase<IBasicSelect>>>
  items: (IReceivedItem | IUnpackedItem)[]
  options: IBasicSelect[]
  isLoading: boolean
  currentValue?: IBasicSelect
  noOptionsMessage?: string
  disabled?: boolean
  updateCurrClient: (id: string | number, creds: {firstName: string; lastName: string}) => void
  setUsersLoading: Dispatch<SetStateAction<boolean>>
  setUsersResults: Dispatch<SetStateAction<IBasicSelect[]>>
}

export const ClientSelect = ({
  selectRef,
  items,
  options,
  isLoading,
  currentValue,
  noOptionsMessage,
  disabled = false,
  updateCurrClient,
  setUsersLoading,
  setUsersResults,
}: Props) => {
  const {setCustomUser} = useUnpackShipmentContext()
  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>
          {noOptionsMessage ? noOptionsMessage : 'Не найдено'}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const loadOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      callback(options)
      return
    }

    setUsersLoading(true)
    getUsers(`search=${inputValue}`)
      .then((res) => {
        const transformed = res?.data?.map((item) => {
          return {
            ...item,
            value: item.externalId || item.id || '',
            label:
              (item.externalId ? item.externalId + ' ' : '') +
              (item.lastName ? item.lastName + ' ' : '') +
              (item.firstName ? item.firstName : ''),
          }
        })
        setUsersResults(transformed || [])
        callback(transformed || [])
      })
      .catch((err) => console.log(err))
      .finally(() => setUsersLoading(false))
  }

  const {setFieldValue} = useFormikContext()

  const handleOnChange = (selectedValue: IBasicSelect | null) => {

    if (selectedValue !== null) {
      setFieldValue('client', selectedValue)
      updateCurrClient(selectedValue?.value || '', {
        // @ts-ignore
        firstName: selectedValue?.firstName,
        // @ts-ignore
        lastName: selectedValue?.lastName,
      })
      setCustomUser(true)
    } else {
      setFieldValue('client', '')
      updateCurrClient('', {
        firstName: '',
        lastName: '',
      })
    }
  }

  // useEffect(() => {
  //   if (items[0] && items[0].type === 3) {
  //     updateCurrClient('', {
  //       firstName: '',
  //       lastName: '',
  //     })
  //     setFieldValue('client', '')
  //   }
  // }, [items])

  return (
    <div
      className={`fv-row d-flex mt-1
      flex-column w-75`}
    >
      <label
        className={`d-flex position-relative fs-5 flex-column fw-bold mb-2`}
        htmlFor={'client'}
      >
        <AsyncSelect
          ref={selectRef}
          styles={customStyles}
          cacheOptions
          className='form-control form-control-lg form-control-solid fs-7 p-0 fw-normal'
          loadOptions={loadOptions}
          defaultOptions
          form={'client'}
          isDisabled={disabled}
          components={{NoOptionsMessage}}
          loadingMessage={() => 'Подождите...'}
          placeholder={'Клієнт'}
          isClearable={true}
          isLoading={isLoading}
          value={currentValue}
          onChange={handleOnChange}
        />
      </label>

      <div className='text-danger'>
        <ErrorMessage name='client.value' />
      </div>
    </div>
  )
}
